import React, {Component, lazy, Suspense} from 'react';
import Preloader from "./components/UI/Preloader/Preloader";
import './leaflet.css';
import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';


const Layout = lazy(() => import('./components/layout'));

class App extends Component {

    render() {

        return (

            <div className="App">
                <Suspense fallback={<Preloader/>}>
                    <Layout/>
                </Suspense>
            </div>

        );
    }
}

export default App;
