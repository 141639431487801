import {InfinitySpin} from "react-loader-spinner";

const Preloader = ({title = 'Načítám data'}) => {

    return (

        <div id="preloader">
            <InfinitySpin
                width='200'
                color="rgb(238, 66, 128)"
            />
            <p><strong>{title}</strong></p>
        </div>

    )
}

export default Preloader;
